body {
  margin: 0;
  background: #f0f5f8;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header{
  padding: 1rem 1rem .5rem;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  margin: 0 0 3rem;
}

#against button{
  appearance: none;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background: rgb(13, 93, 70);
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
}

#settings .react-tabs__tab-list{
  margin: 20px 0;
  border-bottom: none;
  text-align: center;
}

#settings .react-tabs__tab-list li:only-child{
  display: none;
}

#settings .react-tabs__tab{
  padding: .5rem 2rem;
  border-radius: 20px;
  border: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

#settings .react-tabs__tab--selected{
  background: #0d5d46;
  color: #fff;
  font-weight: 500;;
}

#settings .input-group{
  margin: 2rem 0;
}

#settings .input-group p{
  font-size: .75rem;
  color: #515151;
}

#settings .input-group label{
  /* display: block; */
}

#settings .settings{
  padding: 2rem 3rem;
  margin: 2rem 0 4rem;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

#settings .settings .buttons .input-group{
  margin: 1rem 2rem 1rem 0;
  display: inline-block;
}

#settings .input-group .MuiTextField-root{
  width: 100%;
}

#settings .settings .react-list-editable ul{
  list-style: none;
  padding: 0;
  display: inline-block;
}

#settings .settings .react-list-editable ul li{
  display: inline-block;
  margin-right: 1rem;
}

#settings .settings .react-list-editable ul input{
  margin: 0;
}

#settings .settings .react-list-editable button.delete-btn{
  margin: 0 0.25rem;
  background: #fff;
  border: none;
}

#settings .settings .react-list-editable .new-input-field{
  display: block;
  margin: 0;
}

#settings .settings .react-list-editable .input-field{
  background-color: #fff;
  border: 1px solid #c4c4c4;
}

#settings .settings.donationtype .input-group{
    margin: 2rem 0;
    border-bottom: 1px solid #e0e0e0;
    padding: 0px 0 3rem;
}

#settings .settings.donationtype .input-group:last-child{
  border-bottom: none;
}

#settings .settings.donationtype h4{
  margin: 0 0 2rem;
}
